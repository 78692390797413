import { createGlobalStyle } from 'styled-components';
import media, { mediamax } from '../../media';
import { themeColor } from '../../../site-config';

export default createGlobalStyle`
  html {
    font-family: 'Source Sans Pro', Helvetica, sans-serif;
    font-size: 11pt;
    scroll-behavior: smooth;
    ${media.tablet`
      font-size: 12pt;
    `}
    ${media.desktop`
      font-size: 14pt;
    `}
    ${media.extrawide`
      font-size: 18pt;
    `}
  }

  body {
    background-color: #fff;
    font-size: 1rem;
    line-height: 1.65;
  }

  #___gatsby {
    background-color: inherit;
    & > [role="group"] {
      background-color: inherit;
    }
  }

  a {
    color: ${themeColor};
  }

  p {
    margin: 0 0 2rem 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 1rem 0;
    font-weight: 300;
    letter-spacing: -0.05em;
    line-height: 1.375;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  h1 {
    font-size: 3.5rem;
    line-height: 1.2;
  }

  h2 {
    font-size: 2.25rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  h5 {
    font-size: 0.9rem;
  }

  h6 {
    font-size: 0.7rem;
  }

  sub {
    position: relative;
    top: 0.5rem;
    font-size: 0.8rem;
  }

  sup {
    position: relative;
    top: -0.5rem;
    font-size: 0.8rem;
  }

  blockquote {
    padding: 0.5rem 0 0.5rem 2rem;
    border-left: solid 4px;
    margin: 0 0 2rem 0;
    font-style: italic;
  }

  code {
    padding: 0.25rem 0.325rem;
    background-color: rgba(220, 220, 220, 0.5);
    border-radius: 4px;
    font-family: monospace;
    font-size: 0.9em;
  }

  pre {
    margin: 0 0 2rem 0;
    font-family: monospace;
    font-size: 0.9em;

    code {
      display: block;
      padding: 0.75rem 1rem;
      line-height: 1.5;
      overflow-x: auto;
    }
  }

  hr {
    border: 0;
    border-bottom: solid 1px;
    margin: 2.5rem 0;

    &.major {
      margin: 3.5rem 0;
    }
  }

  video {
    max-width: 100%;
  }

  figure {
    margin: 0 0 3rem 0;

    > p,
    > video {
      /* box-shadow: 2px 2px 18px 4px rgba(138, 43, 226, 0.1); */
      display: block;
      margin: 0;
      box-shadow: 2px 2px 18px 4px rgba(0, 0, 0, 0.1);
    }
  }

  figcaption {
    padding-top: 1rem;
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 300;
    /* border-top: solid 1px #aaa; */
    text-align: center;
  }

  ${mediamax.tablet`
    p {
      &.major {
        font-size: 1.1rem;
      }
    }

    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.25rem;
    }

    h4 {
      font-size: 1rem;
    }
  `}
`;
