module.exports = {
  siteTitle: 'Ernie Bello',
  siteTitleShort: 'TenetGatsby',
  siteDescription: 'Personal profile site for Ernie Bello',
  siteUrl: 'https://ernie.bello.nyc',
  themeColor: '#FD5E0F',
  backgroundColor: '#fff',
  // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  pathPrefix: null,
  logo: 'src/images/icon.png',
  social: {
    twitter: '',
    fbAppId: '',
  },
  company: {
    type: 'Organization',
    additionalType: '',
    name: '',
    legalName: '',
    alternateNames: [''],
    email: '',
    address: {
      country: 'United States',
      region: '',
      postalCode: '',
      streetAddress: '',
    },
    sameAs: [
      'https://www.linkedin.com/company/[COMPANY]',
      'https://www.facebook.com/[COMPANY]',
      'https://twitter.com/[COMPANY]',
    ],
    telephone: [],
  },
};
